// src/AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    let navigate = useNavigate();

    const login = () => setIsAuthenticated(true);
    const logout = async () => {
        try {
            await axios.get('/api/me');
            setIsAuthenticated(false);
            navigate('/login')
        } catch (e) {
            setIsAuthenticated(false);
            navigate('/login')
        }
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
