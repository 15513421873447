// src/Transactions.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Transactions() {

    const [trxs, setTrxs] = useState(null);
    useEffect(() => {
        const fetchTrxs = async () => {
            try {
                const response = await axios.get(`/api/trx-list`);
                setTrxs(response.data);
            } catch (error) {
                console.error("Failed to fetch card", error);
            }
        }

        fetchTrxs();
    }, []);


    return trxs && trxs.data && trxs.acc ? (
        <div className="container mt-3">
            {trxs && (<>
                <h2>{trxs.acc} Account Statement</h2>
                <table className="table table2" width="100%">
                    <thead>
                    <tr>
                        {trxs.data[0].map((trx, i) => (
                            <th key={i}>{trx}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {trxs.data.slice(1).map((row, i) => (
                        <tr key={i}>
                            {row.map((col, index) => (
                                <td key={'d'+index}>
                                    {col}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>)}
        </div>
    ) : null;
}
export default Transactions;
