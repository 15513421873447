// src/App.js
import React, { useState, useEffect } from 'react';
import './App.css'; // Import the stylesheet
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import setupInterceptors from './setupInterceptors';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {AuthProvider} from './AuthContext';
import Header from './Header'
import axios from 'axios';
import Login from './Login';
import Transactions from './Transactions';

axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'http://localhost:4001';


function App() {


    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setupInterceptors(setLoading); // Setup Axios interceptors
    }, []);

    return (
        <div className="App App-container">
            {loading && (
                <div className="overlay">
                    <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
            <Router>
                <AuthProvider>
                    <Header/>
                    <Routes>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/transactions" element={<Transactions/>}/>
                        <Route path="/" element={<Login/>}/>
                    </Routes>
                </AuthProvider>
            </Router>
        </div>
    );
;
}

export default App;
