import axios from 'axios';

const setupInterceptors = (setLoading) => {
    axios.interceptors.request.use(config => {
        setLoading(true);  // Show loading overlay
        return config;
    }, error => {
        setLoading(false); // Hide loading overlay on error
        return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
        setLoading(false); // Hide loading overlay on successful response
        return response;
    }, error => {
        setLoading(false); // Hide loading overlay on error
        return Promise.reject(error);
    });
};

export default setupInterceptors;
