// src/Login.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {useAuth} from "./AuthContext";

function Login() {

    const { isAuthenticated, logout, login } = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        checkAuthentication();
    }, []);

    const checkAuthentication = async () => {
        if(isAuthenticated) {
            login()
            navigate('/transactions')
        } else {
            logout()
            navigate('/login');
        }
    };


    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const loginAction = async () => {
        try {
            await axios.post('/api/login', { u: username, p: password }, { withCredentials: true });
            login()
            navigate('/transactions');
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-3">Login</h2>
            <form onSubmit={(e) => { e.preventDefault(); loginAction(); }}>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Enter username"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter password"
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Login</button>
            </form>
        </div>
    );
}

export default Login;
